import React from 'react';
import comma from '../assets/farmerImage/comma.svg';
//farmerImage
import oneImage from '../assets/story_image/one.webp';

import twoImage from '../assets/story_image/two.webp';

import threeImage from '../assets/story_image/three.png';

import fourImage from '../assets/story_image/four.webp';

import fiveImage from '../assets/story_image/economicTimes.webp';

import sixImage from '../assets/story_image/tomatoArticle.webp';

// import "../styles/FarmerOpinion.css";
import Image from 'next/image';
import Link from 'next/link';

export default function Story(text, image, link) {
  return (
    <a target="_blank" rel="noopener noreferrer" href={link}>
      <div className="relative my-12 mx-4 bg-gradient-to-r from-slate-50 to-slate-900 rounded-lg">
        <div className="absolute top-0 w-[100%] h-[100%] z-5 bg-gradient-to-b from-transparent via-transparent to-slate-900 rounded-lg"></div>
        <Image
          sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
          className="rounded-lg"
          style={{
            width: '100%',
            aspectRatio: '4/3',
            objectFit: 'cover',
          }}
          src={image}
          role="presentation"
          srcSet=""
          alt={text}
        />
        <div className="absolute bottom-0 text-slate-50 py-3 px-2">{text}</div>
      </div>
    </a>
  );
}

export const storyArray = [
  {
    text: 'Indian agritech Fyllo partners with Spain’s Terraview for precision agriculture in Europe',
    image: fiveImage,
    link: 'https://economictimes.indiatimes.com/news/economy/agriculture/indian-agritech-fyllo-partners-with-spains-terraview-for-precision-agriculture-in-europe/articleshow/102637444.cms?from=mdr'
  },
  {
    text: 'Tomato crisis. Technology can ease pain points for tomato farmers, help them earn more',
    image: sixImage,
    link: 'https://www.thehindubusinessline.com/opinion/technology-can-ease-pain-points-for-tomato-farmers-help-them-earn-more/article67161917.ece'
  },
  {
    text: 'Bengaluru Startup Helps Farmers Grow Healthy Crops, Save 50 Million Litres of Water',
    image: fourImage,
    link: 'https://www.thebetterindia.com/247857/agritech-sumit-sheoran-sudhanshu-rai-startup-fyllo-bengaluru-agriculture-farming-water-conservation-success-story-him16/',
  },
  {
    text: 'Fyllo and Orchardly Join Hands to Revolutionize Apple Farming in the Valley',
    image: threeImage,
    link: 'https://www.indianweb2.com/2022/12/fyllo-and-orchardly-join-hands-to.html',
  },
  {
    text: 'Agritech Startup Fyllo Secures Funding Led By Triveni Trust, Ninjacart, IAN',
    image: oneImage,

    link: 'https://inc42.com/buzz/agritech-startup-fyllo-secures-funding-led-by-triveni-trust-ninjacart-ian/',
  },
  {
    text: 'IG International partners with agri-tech platform Fyllo to enhance production',
    image: twoImage,

    link: 'https://economictimes.indiatimes.com/tech/startups/ig-international-partners-with-agri-tech-platfrorm-fyllo-to-enhance-production/articleshow/96001741.cms?from=mdr',
  },
];
export const StoriesArray = storyArray.map((story) => {
  return Story(story.text, story.image, story.link);
});
