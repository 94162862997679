//farmerImage
import prakash from '../assets/farmerImage/prakash.png';
import vijaySingh from '../assets/farmerImage/vijay_singh.png';

import comma from '../assets/farmerImage/comma.svg';

import bapu_dabade from '../assets/farmerImage/bapu_dabade.png';
import Shri_Vinod_Kumar_Siag from '../assets/farmerImage/Shri_Vinod_Kumar_Siag.png';

import Image from 'next/image';

export const farmerArray = [
  {
    text: 'My general practice included 11-12 irrigations in the season. This year I have done only 4 irrigations and saved a lot on fertilizers. I have saved around 50-60% on spraying costs. All this with 100% increase in fruitfulness. Thanks to Fyllo.',
    image: vijaySingh,
    farmerName: 'Mr. Vijaysingh Kachare',
    location: 'Telangwadi, Mohol (Solapur)',
    crop: 'Grape',
  },
  {
    text: 'I have a Kinnow garden. There is little water scarcity in our area. Earlier my water used to get over in 3-4 days, but since I am giving water to Kinnow garden according to Fyllo, there is a lot of water saving since then. Due to proper management of fertilizers, the quality of fruits has improved, all are looking alike.',
    image: Shri_Vinod_Kumar_Siag,
    farmerName: 'Shri Vinod Kumar Siag',
    location: 'Fazilka , Punjab',
    crop: 'Kinnow',
  },
  {
    text: '“Every year, I used to have problems in getting canopy. This year, I got best canopy and very good produce while all my nearby farms are facing losses. All this at 60 percent less irrigation and fertigation. Thanks to Fyllo”.',
    image: prakash,
    farmerName: 'Mr. Prakash Chinagundii',
    location: 'Bijjaragi, Karnataka',
    crop: 'Grape',
  },
  {
    text: 'I have 5 acres of pomegranate orchard. For the last two years, I was getting less yield in this garden, because whenever I took the spray, it used to go out due to rain, the water was also getting less and more. But since Fyllo was planted, accurate watering has been done, due to understanding the weather forecast, the sprays have also been accurate. From which you can see that the same size, shining, color and weight of the fruit has also been given well. All this happened because of Fyllo. The garden which was failing since 4 years, I have made it successful this year.',
    image: bapu_dabade,
    farmerName: 'Shri Bapu Dabade',
    location: 'Sangola, Solapur',
    crop: 'Pomegranate',
  },
];

export default function FarmerOpinion(text, image, optimisedImage, farmerName, location, crop) {
  return (
    <div className="w-full md:h-2/3 grid grid-cols-1 place-items-center  ">
      <div className="md:w-3/4 h-[80vh] md:grid md:grid-cols-2  flex flex-col">
        <div className="md:w-2/3 h-full md:pt-28">
          <div className="text-base md:text-sm ">
            <strong className="mb-2 md:text-2xl lg:text-3xl font-bold tracking-tight text-fyllo-800">
              {farmerName}
            </strong>
            <p className="mb-4 md:mb-8 text-lg tracking-tight text-gray-500">
              {crop} Grower from {location}
            </p>
          </div>
          <div>
            <Image
              className="mb-2 md:mb-4 self-start w-10 h-10 md:w-12 object-square"
              src={comma}
              alt="comma"
              srcSet=""
              sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
            />
            <p className="mb-2 text-xs font-light text-gray-700 lg:mb-8 md:text-lg lg:text-xl ">{text}</p>
          </div>
        </div>
        <div className="grid place-items-center">
          <Image
            className=" w-full object-cover aspect-square rounded-md py-5"
            src={image}
            alt={farmerName}
            sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
          />
        </div>
      </div>
    </div>
  );
}

export const FarmerOpinionArray = farmerArray.map((farmer) => {
  return FarmerOpinion(
    farmer.text,
    farmer.image,
    farmer.optimisedImage,
    farmer.farmerName,
    farmer.location,
    farmer.crop
  );
});
