import React, { Children } from 'react';

export default function MainHeading({ text, styles = '', children }) {
  return (
    <h2
      className={
        'mx-auto max-w-screen-md text-fyllo-800 mb-4 text-2xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-4xl  text-center capitalize ' +
        styles
      }
    >
      {text}
      {children ?? null}
    </h2>
  );
}
