import React, { useState, useEffect, useRef } from 'react';
import { animate, motion } from 'framer-motion';

import { useMotionValue, useSpring } from 'framer-motion';

export default function Counter({ from, to, postContent, styleClass = 'text-white font-bold text-2xl md:text-4xl' }) {
  const ref = useRef();

  return (
    <div style={{ display: 'flex' }}>
      {/* <svg
        className="w-8"
        fill="white"
        stroke="white"
        strokeWidth={2}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18" />
      </svg> */}
      <motion.div
        className={styleClass}
        initial="hidden"
        ref={ref}
        onViewportEnter={() => {
          const controls = animate(from, to, {
            duration: 1.5,
            onUpdate(value) {
              if (ref?.current) {
                ref.current.textContent = value?.toFixed(0) ?? 0;
              }
            },
          });
          return () => controls.stop();
        }}
      />
      <div className="text-white font-bold text-2xl md:text-4xl">{postContent}</div>
    </div>
  );
}
