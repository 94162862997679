import React from 'react';
import Link from 'next/link';

// import "../styles/FarmerOpinion.css";
import { cropArray } from '../Data/CropsData';
import { motion } from 'framer-motion';

import capsicum from '../assets/crops/pngs/capsicum.png';

import Image from 'next/image';

export default function CropsCard(text, image) {
  return (
    <div className="relative h-full w-full my-[50px] rounded ">
      <Link href={`/crop/${text}`} state={{ text: text }}>
        <Image
          alt={text}
          className="w-full h-90 rounded-lg hover:shadow-2xl"
          src={image.png}
          sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
        />
      </Link>
    </div>
  );
}

export const CropsArray = cropArray.map((crop) => {
  return CropsCard(crop.text, crop.image);
});
