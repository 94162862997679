import { Navigation, Pagination, Scrollbar, A11y, Thumbs, Controller, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// import "../styles/slider.css";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import 'swiper/css/controller';

const breakPoints = {
  explore: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 24,
    },

    // when window width is >= 640px
    768: {
      slidesPerView: 2,
      spaceBetween: 12,
    },
  },
  fruits: {
    // when window width is >= 320px
    0: {
      slidesPerView: 1,
      spaceBetween: 24,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 24,
    },
    1024: {
      slidesPerView: 5,
      spaceBetween: 32,
      slidesPerGroup: 1,
    },
    1336: {
      slidesPerView: 5,
      spaceBetween: 32,
    },
  },
  default: {},
};

export default function Slider({ imgArray, slidesPerView = 1, sliderName = 'default', isPlay = true }) {
  return (
    <div>
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay]}
        autoplay={isPlay?{
          delay: 2500,
          disableOnInteraction: true,
        }:false}
        watchSlidesProgress
        spaceBetween={10}
        slidesPerView={slidesPerView}
        navigation
        pagination={{ clickable: true }}
        breakpoints={breakPoints[sliderName]}
      >
        <div className="mx-0">
          {imgArray.map((it, index) => {
            return <SwiperSlide key={`slides-${index}`}>{it}</SwiperSlide>;
          })}
        </div>
      </Swiper>
    </div>
  );
}
